<!-- 历史活动 -->
<template>
  <div>
    <div id="historyActivity" :class="!isApp ? 'appTop' : 'unAppTop'">
      <van-list v-model:loading="loading" :finished="finished" offset="200" @load="onLoad">
        <div class="activityList">
          <div
            class="activityItem"
            v-for="(item, index) in list"
            :class="index == list.length - 1 ? '' : 'border-bt'"
            :key="index"
            @click="toPage(item.url)"
          >
            <div class="title">
              {{ item.name }}
              <div class="status">已结束</div>
            </div>
            <div class="msg">
              {{ item.description }}
            </div>
          </div>
        </div>
      </van-list>
    </div>
  </div>
</template>

<script>
import { onMounted, reactive, toRefs } from 'vue';
import { nftUtils } from '@/utils';
import { getactivitylist } from '@/service/invite';

export default {
  name: 'winnerList',
  setup() {
    const state = reactive({
      list: [],
      finished: false,
      page: 1,
      loading: true,
      isApp: nftUtils.isInApp()
    });

    const getList = () => {
      getactivitylist({
        page: state.page,
        page_size: 10
      }).then(res => {
        if (res.status === 0) {
          state.pageLoading = false;
          state.list = state.list.concat(res.data || []);
          state.loading = false;
          if (!res.data || !res.data.length) {
            state.finished = true;
          }
        }
      });
    };

    const onLoad = () => {
      if (!state.finished && state.list.length) {
        console.log(state.page);
        state.page = state.page + 1;
      }
      getList();
    };

    const toPage = url => {
      window.location.href = url;
    };

    onMounted(() => {});

    return {
      ...toRefs(state),
      onLoad,
      toPage
    };
  }
};
</script>

<style lang="scss" scoped>
@import '../styles/mixin.scss';
* {
  touch-action: pan-y;
}
.appTop {
  padding-top: 70px;
}

.unAppTop {
  padding-top: 16px;
}
.activityList {
  padding: 0 20px;
}
.border-bt {
  border-bottom: 1px solid #494359;
}
.activityItem {
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  margin-bottom: 24px;
  padding-bottom: 16px;

  .title {
    position: relative;
    font-size: 16px;
    font-weight: 500;

    .status {
      position: absolute;
      background: #58dedd;
      border-radius: 4px;
      font-size: 12px;
      font-weight: 500;
      color: #1c172a;
      padding: 0 4px;
      line-height: 20px;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .msg {
    margin-top: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    color: rgba(255, 255, 255, 0.5);
  }
}
</style>
